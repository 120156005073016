.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  opacity: .7;
}

.loader {
  margin: auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: .6em solid rgba(92,91,92, 0.2);
  border-right: .6em solid rgba(92,91,92, 0.2);
  border-bottom: .6em solid rgba(92,91,92, 0.2);
  border-left: .6em solid #5c5b5c;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
